<ng-container *ngIf="href; else buttonTemplate">
  <ng-container *ngIf="target; else spa">
    <a [ngClass]="'btn--' + type" [class.gradient]="hasGradient" class="btn" [href]="href" [target]="target">
      <ng-container *ngIf="title; else content">
        <span [innerHTML]="title"></span>
      </ng-container>
      <ng-template #content>
        <ng-content />
      </ng-template>
    </a>
  </ng-container>

  <ng-template #spa>
    <a [ngClass]="'btn--' + type" [class.gradient]="hasGradient" class="btn" [routerLink]="href">
      <ng-container *ngIf="title; else content">
        <span [innerHTML]="title"></span>
      </ng-container>
      <ng-template #content>
        <ng-content />
      </ng-template>
    </a>
  </ng-template>
</ng-container>

<ng-template #buttonTemplate>
  <button
    [ngClass]="'btn--' + type"
    [class.gradient]="hasGradient"
    mat-raised-button
    class="btn"
    [type]="buttonType"
    [disabled]="disabled"
    (click)="clickEvent.emit($event)"
    (keydown.enter)="clickEvent.emit()"
  >
    <ng-container *ngIf="title; else content">
      <span [innerHTML]="title"></span>
    </ng-container>
    <ng-template #content>
      <ng-content />
    </ng-template>
  </button>
</ng-template>
